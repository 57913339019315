@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i');

.table mat-icon {
  padding: 0;
}
.nav-link-with-button {
  height: 45px !important;
}

.tooltipLink p {
  margin-bottom: 0;
}
.error {
  color: red;
}
.space-between {
  justify-content: space-between;
}
.btn-with-small-icon {
  padding:0 !important;
}
.no-margin {
  margin:0 !important;
}
.badge-info {
  color: white !important;
}
.bg-sysx-darkblue {
  background-color: #00688D;
}

.btn:disabled {
  border: 0 !important;
}

.bg-sysx-lightblue {
  background-color: #009DDF;
}

.bg-sysx-orange {
  background-color: #CC5E1E;
}

.filter-op {
  margin-right: 5px;
  margin-left: 5px;
  color: black;
  &:not(:nth-child(2)){
      &:before{
          content: ' or '
      }
  }
}

.fetch-error-background mat-dialog-container {
  height: 25%;
  width: 50%;
  left: 25%;
  top: 37%;
  position: fixed;
  z-index: 1020;
  display: flex;
  align-items: center;
  justify-content: center;
  .mat-mdc-dialog-container {
    height: 20% !important;
  }
  .fetch-error-background-container {
    width: 600px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  div p {
    color: black;
    font-size: 2em;
  }
}

.bg-sysx-gray {
  background-color: #9C9C9C;
}

body {
  margin: 0;
  box-sizing: border-box;
  height: 100%;
  position: relative;

  a {
    text-decoration: none;
  }
}

label {
  display: block;
  font-size: 85%;
  font-weight: bold;
}

.button-tools {
  display: grid;
}

.key {
//  display: inline-block;
//  width: 150px;
  font-size: 85%;
  font-weight: bold;
  padding: 0px 0.5px 3px 0px;
}

.value {
  display: inline-block;

  .badge {
      padding: 10px;
  }
}

.card-header {
  padding: 8px 10px 8px 10px;

  mat-icon {
      margin: 0px 5px 0px 5px;
  }
}

.link-card {
  background-color: #00688D;
  color: white;
  cursor: pointer;
}

.link-card:hover {
  background-color: #CC5E1E;
}

.form-control {
  color: black;
}

mat-icon {
  vertical-align: middle;
}

.toolbar {
  margin-bottom: 20px;
}

.action-button {
  display: inline-block;
  margin: 5px 20px 5px 20px;
  min-width: 120px;
}

.multiline {
  white-space: pre-wrap;
  /*word-break: break-all;*/
}

.tooltip-inner{
  max-width: 400px !important;
}

.big-badge {
  padding: 10px;
}

.keyword {
  color: #00688D;
  font-weight: bold;
  font-size: 90%;
}

// Loading spinner
.spin-loader {
  display: inline-block;
  vertical-align: middle;
  border: 8px solid #f3f3f3;
  border-top: 8px solid darkgray;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.spin-loader-xl {
  margin: 20px;
}
.spin-loader-sm {
  width: 20px;
  height: 20px;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

// Temporary
.infrastructure-dialog .modal-lg {
  max-height: 95%;
  overflow-y: auto;
}

.infrastructure-dialog .modal-lg::-webkit-scrollbar { 
  display: none;
}
// Temporary

.ellipsis {
  position: relative;
}

.ellipsis:before {
  content: '&nbsp;';
  visibility: hidden;
}

.ellipsis > span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clickable {
  cursor: pointer;
}

.table td.text-middle,
.table th.text-middle {
  vertical-align: middle;
}

.col-icon {
  margin-bottom: 10px;
}

.text-rotated {
  white-space: nowrap;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
}

.m-top-2 {
  margin-top: 2px;
}

.collapse-button {
  padding: 0px;
  margin: 0px;
  margin-right: 5px;
}

.icon-col {
  width: 1%;
  vertical-align: top;
}

.tools-col {
  width: 10%;
  vertical-align: top;
}

.cdk-overlay-container {
  z-index: 1100;
}


.mat-paginator-range-label {
  display: none
}

.modal-body{
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

ngb-modal-window {
  z-index: 1055 !important;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.blockquote { 
  display: block;
  font-size: .875em;
  color: #6c757d;
}

.text-success * {
  color: rgba(var(--bs-success-rgb),var(--bs-text-opacity));
}

.text-error * {
  color: rgba(var(--bs-error-rgb),var(--bs-text-opacity));
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.pointer{
  cursor: pointer;
}
ng-dropdown-panel {
  z-index: 10000 !important;
}


.tooltip-warning{
  .tooltip-inner {
      background-color: #FFC107;
    }
    .arrow::before {
      border-left-color: #FFC107;
    }
}

.select-path{
  .ng-option {
    border-bottom: 1px solid rgba(128, 128, 128, .3) !important;
  }
  .ng-select-container{
      height: 100% !important;
      .ng-option-label{
          white-space: normal;
      }
  }
}

.ng-select{
  .ng-placeholder{
    white-space: normal;
  }
}

.big-popover{
  max-width: 100%;
  width: 650px;
  .popover-header { 
    background-color: #343A40;
    color:lightgray;
  }
}

.medium-popover{
  max-width: 100%;
  width: 400px;
  .popover-header { 
    background-color:#343A40;
    color:lightgray;
  }
}

.small-popover{
  max-width: 100%;
  width: 270px;
  .popover-header { 
    background-color:#343A40;
    color:lightgray;
  }
}

.bg-yellow {
  color: #212529;
  background-color: #FCE94F;
}

.bg-light {
  background-color: #F8F9FA !important;
}


.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0px 10px 10px;
  cursor: pointer;
  font-weight: bolder;
  font-size: 20px;
}
.flex {
  display: flex;
}
.help {
  cursor: default;
  mat-icon{
      color: black;
      font-size: 18px;
      height: inherit;
      opacity: 0;
  }
  >div{
      display: inline;
      opacity: 0;
      &.opacity{
          opacity: 1;
      }
  }
  &:hover{
      >div{
          opacity: 1;
      }
      mat-icon{
          opacity: .6;
      }
  }
}

.full-screen-modal {
  .modal-dialog{
    max-width: 80%;
  }
}

#vg-tooltip-element {
  z-index: 10000;
}

.swagger-ui .topbar{
  display: none;
}

.comma-separator {
  span:not(:last-child) {
      &:after {
          content: ", ";
      }
  }
}

@media (min-width: 1200px) {
  .modal-xl,
  .modal-xxl { max-width: 1140px; }
}

@media (min-width: 1400px){
  .modal-xxl {
      max-width: 1360px;
  }
}

.mw-50 {
  max-width: 50% !important; 
}
.blue-snackbar {
    color: #856404;
    background: #fff3cd;
    opacity: 0.25;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-focus:focus{
  box-shadow: none !important;
}

.usecasegroup {
  display: inline-flex;
  height: 20px;
  position: relative;
  bottom: -6px;
}

.usecaseinfo {
  border-radius: 100%;
  margin-right: 10px;
  cursor: default;
  &.input{
      background-color: #00529B;
  }
  &.trigger{
      background-color: #ffad00;
  }
  &.expected{
      background-color: #337B2B;
  }
  &:last-child{
      margin-right: 10px;
  }
}

.default-cursor {
  cursor: default;
}

.component-shown {
  opacity: 100;
}

.component-hidden {
  opacity: 0;
}

.collection-box {
  height:200px;
  .chart {
    width:130px;
    height:130px;
  }
}
